<template>
  <div class="main-logo">
    <a href="/">
      <img
        src="@/assets/img/logos/logo_main.png"
        alt="logo"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<style scoped lang="scss">
.main-logo {
  width: 452px;
  height: 259px;
  margin: 0 auto 120px;

  img {
    max-width: 100%;
    height: auto;
  }

  @include sm {
    width: 350px;
    height: 200px;
    margin: 0 auto 60px;
  }
}
</style>
