<template>
  <main>
    <preloader/>
    <section class="section p-t-80">
      <logo></logo>
    </section>
    <section class="section p-t-0">
      <div class="streamer-block">
        <a :href="channel" target="_blank">
        <div class="streamer">
          <div class="streamer__img">

              <img :src="`/img/streamer/preview-${ previewNum }.png`" class="image-fluid" alt="">
<!--              <img class="image-fluid" :src="`/img/streamer/preview-default.png`" alt="">-->

          </div>
          <div class="streamer__title">
            <span>{{ streamer.name }}</span>
          </div>
        </div>
        </a>
        <div class="subtitle streamer__subtitle">Toamna la Viteză
          <span style="white-space: nowrap">
            Ma<span class="font-emoji">🏎️💨</span>x<span class="font-emoji">💨</span>imă</span>
        </div>
      </div>
      <div class="prize-block">
        <div class="prize">
          <p class="prize__title">Premii</p>
          <p class="prize__subtitle">175 000 lei</p>
        </div>
        <div class="dates">
          <p class="dates__title">Alătură-te echipei mele</p>
          <p class="dates__subtitle">30.09.2024 — 30.10.2024</p>
        </div>
      </div>
      <div class="promo-block">
        <div class="promocode">
          <div class="promocode__code">
            <p class="subtitle">Promo-code</p>
            <p class="subtitle subtitle_accent">{{streamer.promocode}}</p>
          </div>
          <div class="promocode__description">
            - 50 free spins for the "{{streamer.promocode_slot_info}}" slot – worth <span>0,20 RON each</span>.<br/>
            - The minimum deposit to activate the offer is <span>50 RON</span>.<br/>
            - The wagering requirement is <span>15x on winnings from spins</span>.<br/>
            - The maximum value that a player can turn into real money after a free spins bonus is wagered, is
            <span>5,000 RON</span>.<br/>
            - No limits in activations.<br/>
            - This promotion is available in the period of <span>30.09 till 30.10</span> and is active for
            <span>72 hours</span> after entering the promocode in the Bonuses section.
          </div>
        </div>
        <div class="brands">
          <div class="projects">
            <a
              class="projects__item"
              :href="(refLinks && refLinks.frank_ref) ? refLinks.frank_ref : 'https://frankcasino.ro'"
              target="_blank"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/logo-fc-stream.svg"
                alt="frank-casino"
              />
            </a>
            <a
              class="projects__item"
              :href="(refLinks && refLinks.slotv_ref) ? refLinks.slotv_ref : 'https://slotv.ro/ro'"
              target="_blank"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/logo-sv-stream.svg"
                alt="slotv-casino"
              />
            </a>
            <a
              class="projects__item"
              :href="(refLinks && refLinks.mrbit_ref) ? refLinks.mrbit_ref : 'https://mrbit.ro/ro'"
              target="_blank"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/logo-mb-stream.svg"
                alt="mrbit-casino"
              />
            </a>
          </div>
          <div class="providers">
            <a
              href="https://pateplay.com/"
              target="_blank"
              class="providers__item"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/pateplay-stream.svg"
                alt="pateplay"
              >
            </a>
            <a
              href="https://nolimitcity.com/"
              target="_blank"
              class="providers__item"
              style="margin-top: -10px"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/nolimit-stream.svg"
                alt="nolimitcity"
              >
            </a>
            <a
              href="https://www.amusnet.com"
              target="_blank"
              class="providers__item"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/amusnet-stream.svg"
                alt="amusnet"
              >
            </a>
            <a
              href="https://www.pragmaticplay.com/en/"
              target="_blank"
              class="providers__item"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/pragmatic-stream.svg"
                alt="pragmaticplay"
              >
            </a>
            <a
              href="https://www.greentube.com"
              target="_blank"
              class="providers__item"
            >
              <img
                class="image-fluid"
                src="@/assets/img/brands/greentube-stream.svg"
                alt="greentube"
              >
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="block-results">
      <h2 class="block-results__title m-b-30">
        Rezultatele echipei
      </h2>
      <div :class="[{'placeholder': viewPlaceholder}, 'table-container']">
        <div class="blur-content">
          <table class="table m-t-0">
            <thead>
            <tr>
              <th/>
              <th>Poreclă</th>
              <th>Punctaj total</th>
              <th>Premiu</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(row, index) in streamer.result"
              :key="index"
            >
              <td>{{ index + 1 }}.</td>
              <td>{{ row.player_name }}</td>
              <td>{{ row.points }}</td>
              <td>{{ row.prize }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import Preloader from '@/components/Preloader';
import Logo from "@/components/Logo.vue";
export default {
  name: 'Streaming',
  components: {
    preloader: Preloader,
    logo: Logo,
  },
  data() {
    return {
      columns: ['Nickname', 'Total X', 'Prize (lie)'],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    const streamerName = this.$route.path.split('/').pop();
    await this.getStreamer(streamerName);
    if (typeof this.streamer === 'string') {
      await this.$router.replace('/404');
      return;
    }
    this.loading = false;
  },
  computed: {
    ...mapState({
      streamer: (state) => state.streamers.streamer,
    }),
    channel() {
      return this.streamer.channel;
    },
    previewNum() {
      return this.streamer.id < 8 ? this.streamer.id : 'default';
    },
    refLinks() {
      return this.streamer.refs;
    },
    viewPlaceholder() {
      const firstEl = this.streamer?.result?.length ? this.streamer.result[0].points : '-';
      return firstEl === '-';
    },
  },
  methods: {
    ...mapActions('streamers', {
      getStreamer: 'getStreamer',
    }),
    getPlatform(platform) {
      return this.streamer.channel.match(platform);
    },
  },
};
</script>

<style lang="scss">

.streamer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 20px 40px 20px 20px;
  margin-bottom: 20px;
  border-radius: 16px;
  background: rgba(53, 57, 117, 0.35);

  @include sm {
    gap: 0;
  }

  @include sm-phone-width {
    flex-direction: column;
    padding: 20px;
  }

  .streamer {
    display: flex;
    align-items: center;
    padding: 10px 40px 10px 10px;
    margin-right: 20px;
    border-radius: 100px;
    background: #353975;

    @include sm-phone-width {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &__img {
      margin-right: 20px;
      border-radius: 80px;
      border: 2px solid rgba(211, 43, 212, 0.50);
      width: 100px;
      overflow: hidden;
    }

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 178px;
      margin-bottom: 0;
    }

    &__subtitle {
      text-align: right;

      @include sm-phone-width {
        text-align: center;
      }
    }
  }
}

.prize-block {
  width: 100%;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 16px;
  background: rgba(53, 57, 117, 0.35);

  @include sm-phone-width {
    padding: 20px;
  }

  .prize {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 20px;

    @include sm {
      gap: 0;
      justify-content: space-between;
    }

    @include sm-phone-width {
      flex-direction: column;
    }

    &__title {
      font-size: 64px;
      font-style: italic;
      font-weight: 700;
      line-height: normal;

      @include sm-phone-width {
        font-size: 32px;
      }
    }

    &__subtitle {
      color: $primary-color;
      text-align: center;
      font-size: 48px;
      font-style: italic;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      @include sm-phone-width {
        padding: 20px 30px;
        border-radius: 100px;
        background: rgba(53, 57, 117, 0.35);
        font-size: clamp(32px, 12vw, 48px);
      }
    }
  }

  .dates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @include sm {
      gap: 0;
      justify-content: space-between;
    }


    @include sm-phone-width {
      flex-direction: column;
    }

    &__title {
      font-size: clamp(24px, 3vw, 32px);
      font-style: italic;
      font-weight: 700;
      line-height: normal;

      @include sm-phone-width {
        font-size: clamp(24px, 7.5vw, 32px);
      }

      @include xs {
        font-size: 5vw;
      }
    }

    &__subtitle {
      font-size: clamp(18px, 2.5vw, 24px);
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @include sm-phone-width {
        font-size: 24px;
      }

      @include xs {
        font-size: 16px;
      }
    }
  }
}

.promo-block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  @include md {
    flex-direction: column;
  }


  .promocode {
    display: flex;
    flex-basis: 50%;
    padding: 30px;
    gap: 20px;
    border-radius: 8px;
    background: rgba(53, 57, 117, 0.35);

    @media (max-width: 1680px) {
      flex-direction: column;
    }
    @include md {
      flex-direction: row;
    }
    @include sm {
      flex-direction: column;
    }
    @include sm-phone-width {
      padding: 20px;
    }

    &__code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 20px 0;
      background: rgba(53, 57, 117, 0.35);
      border-radius: 8px;
      white-space: nowrap;
      text-align: center;

      .subtitle_accent {
        @include md {
          font-size: 2.5vw;
        }

        @include sm {
          font-size: 32px;
        }
      }

      @include sm {
        padding: 40px;
        text-align: center;
      }

      @include sm-phone-width {
        padding: 40px 20px;
      }
    }

    &__description {
      padding: 20px;
      font-size: 16px;
      border-radius: 8px;
      background: rgba(53, 57, 117, 0.35);

      span {
        color: $primary-color;
      }
    }
  }

  .brands {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;
    gap: 20px;
    padding: 30px;
    border-radius: 16px;
    background: rgba(53, 57, 117, 0.35);


    @include sm-phone-width {
      padding: 20px;
    }

    .projects {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @include sm-phone-width {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 237px;
        height: 135px;
        padding: 10px 30px;
        border-radius: 16px;
        background: rgba(53, 57, 117, 0.35);
        vertical-align: center;

        @include md {
          max-width: 287px;
        }

        @include sm {
          height: 146px;
        }

        @include sm-phone-width {
          height: 85px;
          width: 100%;
          max-width: none;
          padding: 20px;
        }
      }
    }

    .providers {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      height: 50%;
      max-height: 167px;
      gap: 30px;
      padding: 10px 30px;
      border-radius: 16px;
      background: rgba(53, 57, 117, 0.35);

      @media (max-width: 1680px) {
        flex-wrap: wrap;
        gap: 0;
      }

      @include sm {
        height: 146px
      }


      @include sm-phone-width {
        max-height: none;
        height: auto;
      }

      &__item {
        @media (max-width: 1680px) {
          margin: 0 10px 10px;
        }
        @include xs {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}




.subtitle {
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;

  &_accent {
    color: $primary-color;
  }
}

.block-results {
  @extend %block-padding;

  &__title {
    width: 100%;
    text-align: center;
    @extend %block-title;
  }
}

.ref-link {
  width: 100%;
  max-width: 150px;

  img {
    width: 100%;
  }
}

// TABLE

.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px auto 40px;

  .blur-content {
    position: relative;
    z-index: 0;
    width: 100%;
    margin: auto;
    max-width: 800px;
  }

  &.placeholder {

    .blur-content {

      &:after {
        content: 'The participants of the race will appear here soon.';
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }

    }

    .table {
      filter: blur(3px);
    }
  }
}

table, th, td {
  border: none;

  caption {
    caption-side: top;
    color: $normal-white;
    font-size: 16px;
    padding: 0;
  }
}

.table {
  width: 100%;
  margin: auto;
  max-width: 800px;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: $normal-gray;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  background: linear-gradient(0deg, rgba(53, 57, 117, 0.20) 0%, rgba(53, 57, 117, 0.20) 100%), radial-gradient(118.84% 50.08% at 50% 47.15%, rgba(211, 43, 212, 0.19) 0%, rgba(0, 2, 37, 0.00) 72.5%, rgba(9, 4, 45, 0.02) 100%);


  @media(max-width: 576px) {
    margin: 20px 0;
  }

  tbody {
    tr {

      td {
        &:first-child {
          text-align: center;
        }
      }

      &:nth-child(-n+3) {
        td {
          &:first-child {
            font-size: 0;
          }
        }
      }

      @each $i, $w, $h in [[1, 32px, 20px], [2, 20px, 16px], [3, 17px, 14px]] {
        &:nth-child(#{$i}) {
          td {
            &:first-child {
              background: url("../assets/img/icons/place-#{$i}.svg") center no-repeat;
              @include sm {
                background-size: $w $h;
              }
            }
          }
        }
      }
    }
  }
}

.margin-x-0 {
  @media (max-width: 1366px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.margin-y-0 {
  @media (max-width: 1366px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

thead tr {
  border-bottom: none;
}

th {
  padding: 22px 24px 4px 24px;
  white-space: nowrap;
  font-weight: 200;
  color: $normal-white;
  font-size: 18px;

  @media (max-width: 1440px) {
    font-size: 16px;
    padding: 16px 16px 0 16px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    padding: 8px;
  }
}

td {
  font-size: 16px;
  padding: 10px 24px;
  @media (max-width: 1440px) {
    font-size: 14px;
    padding: 8px 16px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    padding: 8px;
  }
}

td, th {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
  white-space: nowrap;
}

.nickname-container {
  position: relative;
  @media (max-width: 576px) {
    max-width: 200px;
  }
  @media (max-width: 400px) {
    max-width: 150px;
  }
  @media (max-width: 360px) {
    max-width: 100px;
  }
  @media (max-width: 320px) {
    max-width: 90px;
  }

  &:after {
    content: '';
    position: absolute;
    right: -2px;
    height: 100%;
    width: 20px;
  }
}

.nickname {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


// BRANDS

</style>
